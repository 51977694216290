import { AgoraVideoPlayer } from "agora-rtc-react";
import { Grid } from "@material-ui/core";
import { useEffect } from "react";

export default function Video(props) {
  const { users, tracks, small } = props;
  // const [gridSpacing, setGridSpacing] = useState(12);

  // const smallVid = {
  //   position: "absolute",
  //   width: "300px",
  //   height: "250px",
  //   right: 0,
  // };

  // const defaultVid = {
  //   position: "relative",
  // };

  // const smallView = props.setView ? smallVid : defaultVid;

  // const handleGrid = () => {
  //   let viewData = props.setView;
  //   if (viewData) {
  //     return 12;
  //   }
  //   return Math.max(Math.floor(12 / (users.length + 1)), 4);
  // };

  useEffect(() => {
    // setGridSpacing(handleGrid);
    // eslint-disable-next-line
  }, [users, tracks, small, props.setView]);

  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
      style={{
        backgroundColor: "#464646",
        height: "100vh",
        paddingTop: "12vh",
        paddingBottom: "15vh",
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AgoraVideoPlayer
          videoTrack={tracks[1]}
          style={{
            height: "100%",
          }}
        />
      </Grid>
      {users.length > 0 &&
        users.map((user, index) => {
          if (user.videoTrack) {
            return (
              <Grid
                item
                xs={6}
                key={index}
                style={{
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  key={user.uid}
                  className="agora_video_player"
                  style={{
                    height: "100%",
                  }}
                />
              </Grid>
            );
          } else return null;
        })}
    </Grid>
  );
}
